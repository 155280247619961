<template>
    <div class="order_content_o">
        <div class="order_header_o">
            <div class="order_img_o">
              <img :src="details.goods_image"/>
            </div>
            <div class="message_item_o">
                <div>{{details.goods_name}}</div>
                <div class="price_item_num_o"><span style="color:rgba(242, 99, 22, 1)">￥{{userFrom.union_member_freeze==0?details.goods_discount_price:details.goods_price}}</span><span>x1</span></div>
                <div class="price_box_o" v-if="userFrom.union_member_freeze==0">享受{{details.goods_sale_range==1?"会员":"股东"}}专有价</div>
            </div>
        </div>
        <div class="select_num_o">
            <div>购买数量</div>
            <div class="numBtn_o">
                <div @click="jianBtn">-</div>
                <input v-model="number" class="numClass_o"/>
                <div @click="addBtn">+</div>
            </div>
        </div>
        <div  class="select_num_o">
            <div>配送方式</div>
            <div v-if="details.goods_freight!=0">快递费:{{details.goods_freight}}</div>
            <div v-else>快递费:免邮</div>
        </div>
        <div  class="select_num_o">
            <div>订单备注</div>
            <input v-model="message" @change="inputChange" class placeholder="选填，请填写额外需求"  />
        </div>
    </div>

</template>
<script>
export default {
  data() {
    return {
      number:1,
      message:''
    }
  },
  props: {
    details:{
      type: Object,
    },
    userFrom:{
      type: Object,
    }
  },
  methods: {
    jianBtn(){
      if(this.number>=2){
        this.number--
      }
      this.$emit("numberBtn",this.number)
    },
    addBtn(){
      this.number++;
      this.$emit("numberBtn",this.number)
    },
    inputChange(){
      this.$emit("inputChange",this.message)
    }
  }
};
</script>
<style>
.order_content_o {
  width: 700px;
  height: 574px;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  opacity: 1;
  border-radius: 5px;
  margin: 25px;
  padding: 43px 35px;
  box-sizing: border-box;
}

.order_header_o {
  display: flex;
  font-size: 25px;
}
.order_img_o {
  width: 160px;
  height: 160px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  opacity: 1;
  border-radius: 0px;
  margin-right: 28px;
}

.order_img_o img{
  width:100%;
  height:100%
}

.message_item_o {
  width: 380px;
}

.price_item_num_o {
  margin: 20px 0;
}
.price_item_num_o span {
  margin-right: 20px;
}
.price_box_o {
  width: 164px;
  height: 28px;
  background: rgba(242, 99, 22, .24);
  border-radius: 0px;
  font-size: 20px;
   color:#F26316;
  text-align: center;
  line-height:28px
}

.select_num_o{
    display: flex;
    justify-content: space-between;
    font-size: 25px;
    margin-top: 40px
}

.select_num_o input{
    border: none
}

.numBtn_o{
    display: flex;
   
}
.numBtn_o div{
    width:42px;height:42px;
    text-align: center;
    line-height:42px;
    border: 1px solid rgba(0, 0, 0, 0.3000)
}
.numClass_o{
    width:50px;
      border: 1px solid rgba(0, 0, 0, 0.3000);
       text-align: center;
}
</style>