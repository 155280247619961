<template>
  <div class="container_order">
    <div class="conter" v-if="address" @click="selectBtn">
      <div class="address_img">
        <img src="../../assets/Slice101.png" />
      </div>
      <div class="address_message">
        <div style="display: flex" class="phone">
          <div class="messageUser">{{address.receiver_name}}</div>
          <div>{{address.receiver_mobile}}</div>
        </div>
        <div>{{address.receiver_address}}</div>
      </div>
      <div class="righticon">
        <img src="../../assets/righticon.png" />
      </div>
    </div>

    <!-- 地址選擇 -->
    <div class="address_p" @click="selectBtn" v-else>
      <div class="address_title">请选择收货地址：</div>
      <div class="address_box">请选择收货地址</div>
    </div>
    <!-- 订单信息 -->
    <order-info :userFrom="userFrom" @numberBtn="numberBtn" @inputChange="inputChange" :details="details"></order-info>

    <div class="submitBtn">
      <div class="goods_num">共{{num}}件，</div>
      <div class="account">合计:</div>
      <div class="a_price">￥{{price}}</div>
      <div class="priceBtn" @click="priceBtn">提交订单</div>
    </div>
  </div>
</template>
<script>
import orderInfo from "../../components/goods/orderInfo";
import { goodsDetails_api, goodsAccess_api, orderAccess_api } from "@/api/deal";
import { Toast } from "vant";
import * as apiCommon from "@/api/common";
import wx from "weixin-js-sdk";
export default {
  data() {
    return {
      details: null,
      address: null,
      num: 1,
      price: null,order_message:null,
      userFrom:{}
    };
  },
  components: {
    orderInfo
  },
  created() {
    document.title = "确认订单";
    this.getDetails(this.$route.query.id);
    this.getUser();
    if (JSON.parse(localStorage.getItem("address"))) {
      this.address = JSON.parse(localStorage.getItem("address"));
      console.log(this.address);
    }
  },
  methods: {
    async getUser() {
      await apiCommon.getuser_api().then(res => {
        console.log(res, "res");
        if (res.code == 0) {
          this.userFrom = res.data;
          console.log(this.userFrom)
        }
      });
    },
    inputChange(data){
      this.order_message = data
    },
    selectBtn() {
      this.$router.push("../../address");
    },
    numberBtn(numberDeta) {
      this.num = numberDeta;
      this.account(this.details);
    },
    getDetails(id) {
      goodsDetails_api(id).then(res => {
        console.log(res);
        if (res.code == 0) {
          this.details = res.data;
          this.account(this.details);
        }
      });
    },
    account(tableData) {
      let data = {
        goods_id: tableData.goods_id,
        goods_num: this.num,
        address_id: this.address.id
      };
      goodsAccess_api(data).then(res => {
        if (res.code == 0) {
          console.log(res);
          this.price = res.data.order_amount;
        }else{
          Toast({
            message: res.error,
            icon: "none",
            duration: 1000
          });
          setTimeout(() => {
            this.$router.go(-1)
          },1000)
        }
      });
    },
    goDetails(){
      let id = localStorage.getItem("id")
      this.$router.push(`./buyOrder?id=${id}`)
    },
    priceBtn() {
      let data = {
        goods_id: this.details.goods_id,
        goods_num: this.num,
        address_id: this.address.id, 
        user_remarks:this.order_message
      };
      orderAccess_api(data).then(res => {
        if (res.code == 0) {
          console.log(res);
          localStorage.setItem("id", res.data.order_info.order_id);
          if (this.price == 0) {
            this.$router.push(`./buyOrder?id=${res.data.order_info.order_id}`);
          } else {
            this.payBtn(res);
          }
        } else {
          // Toast({
          //   message: res.error,
          //   icon: "none",
          //   duration: 1000
          // });
        }
      });
    },
 
    async payBtn(res) {
      let that = this
      Toast.loading({
        message: "加载中...",
        forbidClick: true
      });
      let res1 = await apiCommon.getConfig();
      console.log(res1);
      let tableData = res1.data;
      wx.config(tableData);
      wx.chooseWXPay({
        timestamp: res.data.pay_info.timestamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
        nonceStr: res.data.pay_info.nonceStr, // 支付签名随机串，不长于 32 位
        package: res.data.pay_info.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
        signType: "MD5", // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
        paySign: res.data.pay_info.paySign, // 支付签名
        success(res) {
          if (res.errMsg == "chooseWXPay:ok") {
            let id = localStorage.getItem("id")
            that.$toast("支付成功");
            that.$router.push(`./buyOrder?id=${id}`);
          } else {
            alert(res.errMsg);
            that.$toast("支付失败", "middle");
          }
        },
        cancel: function(res) {
          that.$toast("支付取消", "middle", res);
        }
      });
    }
  }
};
</script>
<style>
.van-toast {
  width: 300px;
  min-height: 120px;
  line-height:50px
}
.van-toast__text {
  font-size: 35px;
}
.van-icon {
  font-size: 40px;
}
.van-dialog {
  width: 500px;
  font-size: 35px !important;
}
.van-dialog__message {
  font-size: 25px;
  line-height: 40px;
}
.conter {
  width: 700px;
  height: 180px;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  opacity: 1;
  border-radius: 5px;
  margin: 30px auto 25px;
  padding: 52px 25px 52px 30px;
  box-sizing: border-box;
  display: flex;
}
.address_img {
  width: 52px;
  height: 65px;
}
.address_img img {
  width: 100%;
  height: 100%;
}
.address_message {
  font-size: 20px;
  color: rgba(0, 0, 0, 0.5);
  margin: 0 110px 0 11px;
}
.phone {
  margin-bottom: 20px;
}
.messageUser {
  font-size: 25px;
  color: rgba(0, 0, 0, 1);
}
.container_order {
  width: 100%;
  height: 100vh;
  background-color: rgba(246, 246, 249, 1);
}

.submitBtn {
  display: flex;
  width: 700px;
  margin: 0 auto;
  height: 100px;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  opacity: 1;
  border-radius: 5px;
  font-size: 25px;
  line-height: 100px;
}
.goods_num {
  font-size: 20px;
  color: rgba(0, 0, 0, 0.5);
  margin-right: 10px;
  margin-left: 236px;
}
.account {
  font-size: 25px;
  margin-right: 10px;
}
.a_price {
  color: rgba(242, 99, 22, 1);
  font-size: 30px;
}
.priceBtn {
  width: 135px;
  height: 46px;
  background: #f26316;
  opacity: 1;
  border-radius: 23px;
  font-size: 20px;
  text-align: center;
  line-height: 46px;
  color: #ffffff;
  margin: 27px 0 0 41px;
}
.address_p {
  margin-top: 30px;
  margin-left: 35px;
}
.address_title {
  font-size: 30px;
  color: #fff;
  margin-left: 10px;
}
.address_box {
  width: 680px;
  height: 170px;
  border-radius: 10px 10px 10px 10px;
  opacity: 1;
  border: 2px solid rgba(204, 175, 124, 0.6);
  line-height: 170px;
  text-align: center;
  font-size: 30px;
  color: #e3c084;
  margin: 36px 0;
  position: relative;
}
.righticon img {
  width: 40px;
  height: 50px;
  position: absolute;
  top: 90px;
  right: 50px;
}

.popBox {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
.box_log {
  width: 703px;
  opacity: 1;
  margin: 300px auto 0;
  background-color: #fff;
}
.box_log div {
  height: 124px;
  line-height: 124px;
  text-align: center;
  font-size: 30px;
  border-bottom: 1px solid #eee;
}
</style>